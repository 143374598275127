(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-navigation/assets/javascripts/use-navigation-state.js') >= 0) return;  svs.modules.push('/components/racing/race-navigation/assets/javascripts/use-navigation-state.js');
"use strict";


const {
  useMemo
} = React;
const {
  useLocation,
  useRouteMatch
} = ReactRouterDOM;
const {
  getFromParams
} = svs.racing.navigation.state;
const useNavigationState = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const navstate = useMemo(() => getFromParams(match, location.search), [match, location.search]);
  return navstate;
};
setGlobal('svs.racing.navigation.useNavigationState', useNavigationState);

 })(window);