(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-navigation/assets/javascripts/state.js') >= 0) return;  svs.modules.push('/components/racing/race-navigation/assets/javascripts/state.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  urlNameToGameType,
  urlParts,
  couponMenuItems,
  couponMenuItemsList,
  statisticsMenuItems,
  statisticsMenuItemsList
} = svs.racing.common.constants;
const getFromPathParams = match => {
  if (match.path.startsWith('/mina-spel')) {
    return {
      isMyGaming: true,
      wagerId: match.params.wagerId
    };
  }
  return {
    isMyGaming: false,
    day: match.params.day,
    trackName: match.params.trackName,
    raceNumber: match.params.raceNumber,
    gameType: urlNameToGameType[match.params.gameType],
    isXpert: Boolean(match.params.isXpert),
    participantName: match.params.participantName,
    participantId: match.params.participantId
  };
};
const getFromQueryParams = locationSearch => {
  const query = new URLSearchParams(locationSearch);
  const couponMenuItem = query.get(urlParts.COUPON_MENU);
  const tab = query.get(urlParts.TAB);
  const mode = query.get(urlParts.MODE);
  const type = query.get(urlParts.TYPE);
  const statisticsMenuItem = query.get(urlParts.STATISTICS_TAB);
  const showLive = query.get(urlParts.SHOW_LIVE);
  const activatedDrawId = query.get(urlParts.ACTIVATED_DRAW_ID); 
  const isRetailerAdmin = query.get(urlParts.IS_RETAILER_ADMIN); 
  const fromPlayTogether = query.get(urlParts.FROM_PLAY_TOGETHER); 
  const wagerExamined = query.get(urlParts.WAGER_EXAMINED);
  const row = query.get(urlParts.ROW);
  const pixMode = query.get(urlParts.PIX_MODE);
  return {
    couponMenuItem: couponMenuItemsList.includes(couponMenuItem) ? couponMenuItem : couponMenuItems.COUPON,
    isSystem: couponMenuItem === couponMenuItems.SYSTEM,
    tab,
    mode,
    activatedDrawId,
    isRetailerAdmin,
    fromPlayTogether,
    type,
    showLive,
    statisticsMenuItem: statisticsMenuItemsList.includes(statisticsMenuItem) ? statisticsMenuItem : statisticsMenuItems.OVERVIEW,
    wagerExamined: wagerExamined !== 'false',
    row,
    pixMode
  };
};
const getFromParams = (match, locationSearch) => _objectSpread(_objectSpread({}, getFromPathParams(match)), getFromQueryParams(locationSearch));
setGlobal('svs.racing.navigation.state', {
  getFromPathParams,
  getFromQueryParams,
  getFromParams
});

 })(window);