(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-navigation/assets/javascripts/use-navigation-actions.js') >= 0) return;  svs.modules.push('/components/racing/race-navigation/assets/javascripts/use-navigation-actions.js');
"use strict";


const {
  useMemo
} = React;
const {
  useHistory,
  useLocation,
  useRouteMatch
} = ReactRouterDOM;
const {
  navigate
} = svs.racing.navigation;
const useNavigationActions = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const actions = useMemo(() => navigate(match, location, history), [history, location, match]);
  return actions;
};
setGlobal('svs.racing.navigation.useNavigationActions', useNavigationActions);

 })(window);