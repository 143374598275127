(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-navigation/assets/javascripts/navigate.js') >= 0) return;  svs.modules.push('/components/racing/race-navigation/assets/javascripts/navigate.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  gameTypeHasXpert
} = svs.racing.common.util;
const {
  getFromParams
} = svs.racing.navigation.state;
const {
  buildLocation
} = svs.racing.navigation.location;


const navigateTo = (history, replace, navState) => {
  let newLocation = buildLocation(navState);
  newLocation = _objectSpread(_objectSpread({}, newLocation), {}, {
    state: _objectSpread(_objectSpread({}, newLocation.state || {}), {}, {
      internalLink: true
    })
  });
  if (replace) {
    history.replace(newLocation);
  } else {
    history.push(newLocation);
  }
};
const navigate = (match, location, history) => ({
  toRace: (day, trackName, raceNumber, gameType) => {
    const navState = getFromParams(match, location.search);
    navState.day = day;
    navState.trackName = trackName;
    navState.raceNumber = raceNumber;
    navState.gameType = gameType ? gameType : navState.gameType;
    navState.isXpert = gameType ? gameTypeHasXpert(gameType) && navState.isXpert : gameTypeHasXpert(navState.gameType) && navState.isXpert;
    navState.participantId = null;
    navState.participantName = null;
    navigateTo(history, false, navState);
  },
  toGameType: gameType => {
    const navState = getFromParams(match, location.search);
    navState.gameType = gameType;
    navState.isXpert = gameTypeHasXpert(gameType) && navState.isXpert;
    navigateTo(history, true, navState);
  },
  toXpert: isXpert => {
    const navState = getFromParams(match, location.search);
    navState.isXpert = isXpert;
    navigateTo(history, true, navState);
  },
  toCouponItem: couponMenuItem => {
    const navState = getFromParams(match, location.search);
    navState.couponMenuItem = couponMenuItem;
    navigateTo(history, true, navState);
  },
  toParticipant: participant => {
    const navState = getFromParams(match, location.search);
    navState.participantName = participant ? participant.horseNameUrl : undefined;
    navState.participantId = participant ? participant.participantId : undefined;
    navigateTo(history, false, navState);
  },
  toTab: tab => {
    const navState = getFromParams(match, location.search);
    navState.tab = tab;
    navigateTo(history, false, navState);
  },
  toStatisticsItem: statisticsMenuItem => {
    const navState = getFromParams(match, location.search);
    navState.statisticsMenuItem = statisticsMenuItem;
    navigateTo(history, false, navState);
  },
  toRaceWithRow: (day, trackName, raceNumber, row, gameType, clearTab) => {
    const navState = getFromParams(match, location.search);
    navState.day = day;
    navState.trackName = trackName;
    navState.raceNumber = raceNumber;
    navState.row = row;
    navState.gameType = gameType;
    navState.tab = clearTab ? undefined : navState.tab;
    navState.isXpert = false;
    navigateTo(history, false, navState);
  },
  toPixMode: pixMode => {
    const navState = getFromParams(match, location.search);
    navState.pixMode = pixMode;
    navigateTo(history, true, navState);
  }
});
setGlobal('svs.racing.navigation.navigate', navigate);

 })(window);